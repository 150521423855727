@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
body {
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, sans-serif,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-360-gray-500
}

.bebas{
  font-family: 'Bebas Neue', -apple-system, BlinkMacSystemFont, sans-serif,
      sans-serif;
}